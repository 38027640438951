import React, {useEffect, useState} from 'react';
import './ChatApp.css';

const ChatApp = () => {
    // 定义消息列表和当前输入的消息状态
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleClick = async () => {
        if (inputValue.trim() !== '') {
            // 添加新消息到消息列表
            setMessages((prevMessages) => [...prevMessages, { text: inputValue, isMine: true }]);
            // 清空输入框内容
            setInputValue('');
            // 接收到一条新消息
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', 'Bearer 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');

            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    model: 'text-davinci-003',
                    prompt: inputValue,
                    temperature: 0,
                    max_tokens: 200
                })
            };
            const response = await fetch('https://api.openai.com/v1/completions', options);
            const json = await response.json();
            const jsonStr = JSON.stringify(json);
            const jsonObj = JSON.parse(jsonStr);
            setMessages((prevMessages) => [...prevMessages, { text: jsonObj.choices[0].text, isMine: false }]);

        }
    };

    // 处理消息输入框内容变化
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    // 处理发送按钮点击事件
    const handleSendMessage = () => {
        if (inputValue.trim() !== '') {
            // 添加新消息到消息列表
            setMessages((prevMessages) => [...prevMessages, { text: inputValue, isMine: true }]);
            // 模拟接收到一条新消息
            setTimeout(() => {
                setMessages((prevMessages) => [...prevMessages, { text: 'OK', isMine: false }]);
            }, 1000);
            // 清空输入框内容
            setInputValue('');
        }
    };

    return (
        <div>
            <div>
                <div style={{ display: 'flex', height: '80vh', padding: 0}}>
                    <div style={{ flex: 1, backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px', overflow: 'auto'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '10px' }}>
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    style={{
                                        alignSelf: message.isMine ? 'flex-end' : 'flex-start',
                                        backgroundColor: message.isMine ? 'white' : '#ddd',
                                        padding: '10px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    {message.text}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', marginTop: '16px', display: 'flex', gap: '10px', position: 'fixed'}}>
                <div style={{flex: 0.1}}></div>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{ backgroundColor: '#f5f7f9', flex: 1, marginRight: '10px', padding: '10px', borderRadius: '10px', border: 'none' }}
                />
                <button onClick={handleClick} className="sendButton">
                    Send
                </button>
                <div style={{flex: 0.1}}></div>
            </div>


        </div>


    );
};

export default ChatApp;